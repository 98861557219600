.container-banner {
    display: flex;
    flex-direction:row;
    color: aliceblue;
    margin-top: 7%;
    padding: 1em;
    /* box-shadow: 0px 0px 20px rgba(59, 59, 59, 0.3); */
    width: 90%;
    height: 100%;
    margin-left: 5%;
}

.bienvenido{
    width: 55%;
    height: 100%;
    margin-right: 7%;
}

.portafolio {
    color: aliceblue;
    border: 1px solid white;
    width: 15em;
    height: 2.7em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90.21deg,rgba(170,54,124,.5) -5.91%,rgba(74,47,189,.5) 111.58%);
    border: 1px solid hsla(0,0%,100%,.5);
    font-size: 1.3em;
    margin-bottom: 1em;
}

.title{
    text-align: left;
    display: block;
    font-size: 2.5em;
    line-height: 1;
    letter-spacing: .8px;
}

.parrafo{
    color: #b8b8b8;
    font-size: 18px;
    letter-spacing: .8px;
    line-height: 1.5em;
    width: 96%;
    text-align: left;
    display: flex;
    margin-top: 0px;
}

.imagenn {
    width: 100%;
    margin-right: 2em;
    object-fit: contain; 
    margin-bottom: 1em;
    height: 90%;
}

.conteiner-image{
    margin-bottom: 2em;
    width: 26%;
}




.btnn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 13rem;
    height: 3rem;
    margin-top: 1.8em !important;
    background-size: 300% 300%;
    backdrop-filter: blur(1rem);
    border-radius: 5rem;
    transition: 0.5s;
    animation: gradient_301 5s ease infinite;
    border: double 4px transparent;
    background-image: linear-gradient(#212121, #212121), linear-gradient(137.48deg, #8e51ead7 0%, #0044ffd7 25% , #8e51ead7 75% ,#0044ffd7 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  
  #container-stars {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: 0.5s;
    backdrop-filter: blur(1rem);
    border-radius: 5rem;
  }
  
  strong {
    z-index: 2;
    /* font-family: 'Avalors Personal Use'; */
    font-size: 12px;
    letter-spacing: 5px;
    color: #FFFFFF;
    text-shadow: 0 0 4px white;
  }
  
  #glow {
    position: absolute;
    display: flex;
    width: 12rem;
  }
  
  .circle {
    width: 100%;
    height: 30px;
    filter: blur(2rem);
    animation: pulse_3011 4s infinite;
    z-index: -1;
  }
  
  .circle:nth-of-type(1) {
    background: rgba(254, 83, 186, 0.39);
  }
  
  .circle:nth-of-type(2) {
    background: rgba(142, 81, 234, 0.473);
  }
  
  .btnn:hover #container-stars {
    z-index: 1;
    background-color: #212121;
  }
  
  .btnn:hover {
    transform: scale(1.1)
  }
  
  .btnn:active {
    border: double 4px #fe53ba71;
    background-origin: border-box;
    background-clip: content-box, border-box;
    animation: none;
  }
  
  .btnn:active .circle {
    background: #fe53ba73;
  }
  
  #stars {
    position: relative;
    background: transparent;
    width: 200rem;
    height: 200rem;
  }
  
  #stars::after {
    content: "";
    position: absolute;
    top: -10rem;
    left: -100rem;
    width: 100%;
    height: 100%;
    animation: animStarRotate 90s linear infinite;
  }
  
  #stars::after {
    background-image: radial-gradient(#ffffff 1px, transparent 1%);
    background-size: 50px 50px;
  }
  
  #stars::before {
    content: "";
    position: absolute;
    top: 0;
    left: -50%;
    width: 170%;
    height: 500%;
    animation: animStar 60s linear infinite;
  }
  
  #stars::before {
    background-image: radial-gradient(#ffffff 1px, transparent 1%);
    background-size: 50px 50px;
    opacity: 0.5;
  }
  
  @keyframes animStar {
    from {
      transform: translateY(0);
    }
  
    to {
      transform: translateY(-135rem);
    }
  }
  
  @keyframes animStarRotate {
    from {
      transform: rotate(360deg);
    }
  
    to {
      transform: rotate(0);
    }
  }
  
  @keyframes gradient_301 {
    0% {
      background-position: 0% 50%;
    }
  
    50% {
      background-position: 100% 50%;
    }
  
    100% {
      background-position: 0% 50%;
    }
  }
  
  @keyframes pulse_3011 {
    0% {
      transform: scale(0.75);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
  
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
  
    100% {
      transform: scale(0.75);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }



@media (max-width: 880px) {
    .container-banner{
        width: 100%;
        margin-top: 3em;
        flex-direction: column;
    }
    .bienvenido{
        width: 90%;
        height: 100%;
        margin-right: 10%;
    }
    .conteiner-image{
        width: 90%;
        margin-top: 1em;
    }


}