.container-social {
  width: 18em;
  position: relative;
  text-align: right;
  margin-left: auto; /* Añadido para centrar horizontalmente el contenedor */
  margin-right: 1em; /* Ajustado el margen derecho según tus necesidades */
}

.social-buttons {
  display: flex;
  justify-content: flex-end; /* Cambiado a "flex-end" en lugar de "right" */
  align-items: center;
  background-color: transparent;
  box-shadow: 0px 0px 15px #00000018;
  border-radius: 5em;
  /* margin-top: 1em; */
  margin-right: 1.5em !important;
}

.social-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.125em;
  height: 3.125em;
  border-radius: 50%;
  margin: 0 8px; /* Cambiado el margen a 5px en lugar de 10px */
  background-color: #fff;
  box-shadow: 0px 0px 4px #00000027;
  transition: 0.3s;
}
  
.social-button:hover {
  background-color: #f2f2f2;
  box-shadow: 0px 0px 6px 3px #00000027;
}
  
.social-buttons svg {
  transition: 0.3s;
  height: 20px;
}
  
.facebook {
  background-color: #3b5998;
}
  
.facebook svg {
  fill: #f2f2f2;
}
  
.facebook:hover svg {
  fill: #3b5998;
}
  
.github {
  background-color: #333;
}
  
.github svg {
  width: 25px;
  height: 25px;
  fill: #f2f2f2;
}
  
.github:hover svg {
  fill: #333;
}
  
.linkedin {
  background-color: #0077b5;
}
  
.linkedin svg {
  fill: #f2f2f2;
}
  
.linkedin:hover svg {
  fill: #0077b5;
}
  
.instagram {
  background-color: #c13584;
}
  
.instagram svg {
  fill: #f2f2f2;
}
  
.instagram:hover svg {
  fill: #c13584;
}

@media (max-width: 880px) {
  .container-social {
    /* width: 100%;  */
    position: static; 
    margin: 0px;
    padding: 0px 10px !important;
    justify-content: right;
  }
  
  .social-buttons {
    justify-content: center; 
    padding: 0px;
    margin-right: 0; /* Eliminamos el margen derecho en pantallas pequeñas */
  }
  
  .social-button {
    margin: 0 3px; /* Reducimos el margen entre los botones */
  }
}
