@import 'variables';

html {
  --font-a: 'Montserrat', sans-serif;
  --color-white: #c0c0c0;
  --color-black: #191919;
  --color-bg: #191919;
  --color-a: #de4747;
  box-sizing: border-box;
}

body {
  font-family: var(--font-a);
  background-color: var(--color-bg);
  color:  rgb(58, 58, 58);
  font-size: 16px;
  line-height: 18px;
  user-select: none;
}


.js-cursor {
  --size: 20px;
  position: fixed;
  width: var(--size);
  height: var(--size);
  top: calc(var(--size) / -2);
  left: calc(var(--size) / -2);
  transform: translate(var(--cursor-x, -100px), var(--cursor-y, -100px)) translateZ(0);
  pointer-events: none;
  transition: none;
  z-index: 11000;
  will-change: transform;

  &.is-debug {
    transition: all 0.2s ease-in-out;
  }

  &__inner {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--color-white);
    border: 1px solid  rgb(58, 58, 58);
    transition: all 0.15s ease-in-out;
    opacity: 1;
    transform: scale(1) translateZ(0);
  }

  &.is-clicked &__inner {
    opacity: 0.5;
  }

  &.is-hidden &__inner {
    opacity: 0;
  }

  &.is-link-hovered &__inner {
    transform: scale(0.75) translateZ(0);
    background-color: var(--color-a);
  }
}

.has-custom-cursor {
  &,
  * {
    cursor: none !important;
  }
}

@mixin md {
  @media (min-width: 610px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 1191px) {
    @content;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}


::selection {
  color:  rgb(58, 58, 58);
  background-color: var(--color-white);
}

.o-font-title {
  font-size: 30px;
  line-height: 46px;
  font-weight: 700;

  @include md {
    font-size: 50px;
    line-height: 65px;
  }

  @include lg {
    font-size: 80px;
    line-height: 96px;
  }
}

.o-font-text {
  font-size: 14px;
  line-height: 22px;
  font-weight: 300;

  @include md {
    font-size: 16px;
    line-height: 24px;
  }

  @include lg {
    font-size: 18px;
    line-height: 26px;
  }
}

.o-font-button {
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;

  @include md {
    font-size: 14px;
    line-height: 22px;
  }

  @include lg {
    font-size: 16px;
    line-height: 24px;
  }
}

.o-container {
  width: 960px;
  max-width: 100%;
  display: block;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.o-pt {
  padding-top: 100px;
}

.c-text {
  padding-bottom: 60px;

  &__title {
    padding-bottom: 20px;
  }

  &__content {
    padding-bottom: 40px;
  }

  &__button {
    display: inline-block;
    width: auto;
    padding: 4px 10px;
    text-align: center;
    min-width: 120px;
    border: 1px solid  rgb(58, 58, 58);
    background-color: black;
    color: var(--color-black);
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: var(--color-white);
      background-color: var(--color-black);
    }
  }
}

.c-image {
  display: block;
  width: 70%;
  padding-bottom: 60px;

  &__image {
    display: block;
    width: 100%;
    height: auto;
  }
}

.c-cursor {
  --size: 20px;
  position: fixed;
  width: var(--size);
  height: var(--size);
  top: calc(var(--size) / -2);
  left: calc(var(--size) / -2);
  transform: translate(var(--cursor-x, -100px), var(--cursor-y, -100px)) translateZ(0);
  pointer-events: none;
  transition: none;
  z-index: 11000;
  will-change: transform;

  .is-debug & {
    transition: all 0.2s ease-in-out;
  }

  &__inner {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--color-white);
    border: 1px solid  rgb(58, 58, 58);
    transition: all 0.15s ease-in-out;
    opacity: 1;
    transform: scale(1) translateZ(0);
  }

  &.is-clicked &__inner {
    opacity: 0.5;
  }

  &.is-hidden &__inner {
    opacity: 0;
  }

  &.is-link-hovered &__inner {
    transform: scale(0.75) translateZ(0);
    background-color: var(--color-a);
  }
}

// CURSOR POR ARRIBA DEL BOTON
.is-link-hovered {
  & .c-cursor__inner {
    background-color: rgb(16, 60, 97);
    transform: scale(0.8); /* Ajusta el valor según tus necesidades */
    border: 1px solid rgb(58, 58, 58);
  }
}




.has-custom-cursor {
  &,
  * {
    cursor: none !important;
  }
}

.c-controls {
  display: none;

  .has-custom-cursor & {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: auto;
    height: auto;
    z-index: 100;
    display: flex;
    align-items: center;
  }

  

  &__button {
    width: auto;
    padding: 4px 10px;
    background-color: var(--color-a);
    color: var(--color-black);
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    margin-right: 20px;

    &.is-active {
      background-color: var(--color-white);
      color: var(--color-black);
    }
  }
}