.contact {
    display: flex;
    padding: 60px 0 200px 0;
    position: relative;
    align-items: center;
    text-align:right;
    width: 100%;
  }
  
  .contact img {
    float: left;
    margin-top: 1em;
    width: 40%;
    margin-left: 4%;
  }
  
  .contact form {
    margin-top: 3%;
    float: right;
    width: 42%;
    margin-right: 7%;
  }

  
  .contact h2{
    text-align:center; 
    font-size: 45px;
    font-weight: 700;
    color: #fffcfc;
    margin-bottom: 3%;
    justify-content: center; 
    line-height: 1;
  }
  
  .contact form input,
  .contact form textarea {
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    color: #fff;
    margin: 0 0 8px 0;
    padding: 18px 26px;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.8px;
    transition: 0.3s ease-in-out;
  }
  
  .contact form input:focus,
  .contact form textarea:focus {
    background: rgba(255, 255, 255, 1);
    color: #121212;
  }
  
  .contact form input::placeholder,
  .contact form textarea::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
  }
  
  .contact form input:focus::placeholder,
  .contact form textarea:focus::placeholder {
    color: #121212;
    opacity: 0.8;
  }
  
  .contact form button {
    font-family: inherit;
    font-size: 20px;
    background: rgba(28, 79, 145, 0.795);
    color: white;
    padding: 0.7em 1em;
    padding-left: 0.9em;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 16px;
    overflow: hidden;
    transition: all 0.2s;
  }
  
  .contact form button span {
    display: block;
    margin-left: 0.3em;
    transition: all 0.3s ease-in-out;
  }
  
  .contact form button svg {
    display: block;
    transform-origin: center center;
    transition: transform 0.3s ease-in-out;
  }
  
  .contact form button:hover .svg-wrapper {
    animation: fly-1 0.6s ease-in-out infinite alternate;
  }
  
  .contact form button:hover svg {
    transform: translateX(1.2em) rotate(45deg) scale(1.1);
  }
  
  .contact form button:hover span {
    transform: translateX(5em);
  }
  
  .contact form button:active {
    transform: scale(0.95);
  }
  
  @keyframes fly-1 {
    from {
      transform: translateY(0.1em);
    }
  
    to {
      transform: translateY(-0.1em);
    }
  }
  
  



  /* .contact form button {
    margin-right: 1em;
    font-weight: 700;
    color: #000;
    background-color: #fff;
    padding: 14px 48px;
    font-size: 18px;
    margin-top: 25px;
    border-radius: 0;
    position: relative;
    transition: 0.3s ease-in-out;
  }
  
  .contact form button span {
    z-index: 1;
    position: relative;
  }
  
  .contact form button:hover {
    color: #fff;
  }
  
  .contact form button::before {
    content: "";
    background: #121212;
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transition: 0.3s ease-in-out;
  }
  
  .contact form button:hover::before {
    width: 100%;
  } */
  
  p.success {
    color: green;
  }
  
  p.danger {
    color: red;
  }
  
  @media (max-width: 550px) {
    .contact {
      flex-direction: column;
      text-align: center;
      padding: 0px;
      padding-bottom: 30px;
    }
    
    .contact img {
      float: none;
      width: 70%; /* Ajusta este valor según sea necesario */
      margin: 0 auto;
      margin-bottom: .8em;
    }
    
    .contact form {
      float: none;
      width: 80%; /* Ajusta este valor según sea necesario */
      margin: 0 auto;
      margin-top: 3%; /* Ajusta este valor según sea necesario */
    }

    .contact h2{
      margin-bottom: .8em;
      }

  }
  