/* Skills.css */

.skill {
  padding: 0 0 0px 0;
  position: relative;
  padding: 0 5em;
}

.skill-bx {
  background: #151515f6;
  border-radius: 64px;
  text-align: center;
  padding: 4em 4em;
  margin-top: 5em;
  margin-bottom: 5em;
}

.skill h2 {
  font-size: 45px;
  font-weight: 700;
  color: #fffcfc;
  margin-bottom: 1em;
}

.skill p {
  color: #b8b8b8;
  margin-top: 1em;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}

.skill-slider .item h5 {
  color: #fffcfc;
}

.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

@media (max-width: 768px) {
  .skill{
    padding: 0px 30px;
  }
  .skill-bx {
    padding: 3em;
    margin-top: 0em;
   
  }

  .skill h2 {
    font-size: 30px;
  }

  .skill-slider {
    width: 100%;
  }

  .skill-slider .item img {
    width: 80%;
  }
  /* .container{
    width: 90%;
  } */
}
